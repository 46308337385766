<template>
  <section class="hero">
    <div class="wrapper">
      <div class="info">
        <h1 v-html="$t('index.hero.title')"/>
        <p v-html="$t('index.hero.description')" />

        <div class="actions">
          <Buttons.Regular
            fill="solid"
            accent="purple"
            data-id="hero-downloadAsset"
            @click="setFormState('visible', true)"
          >
            {{ $t('Download_White_Paper') }}
            <SvgArrowForward />
          </Buttons.Regular>

          <Buttons.Link
            class="action-request-demo"
            fill="outline"
            accent="orange"
            :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
          >
            {{ $t('Request_a_Demo') }}
            <SvgArrowForward />
          </Buttons.Link>
        </div>

        <NuxtImg
          src="/redesign/images/server/server-hero.webp"
          :alt="$t('index.hero.alt')"

          width="1920"
          height="921"

          sizes="xs:560px sm:923px md:1216px lg:800px xl:944px 3xl:1071px"
          densities="x1 x2"
          format="webp"
          preload

          fetchpriority="hight"
        />
      </div>
    </div>
  </section>

  <PartnersMarquee />

  <section class="ootbi">
    <div class="wrapper">
      <h2 v-html="$t('index.ootbi.title')" />

      <p
        class="description-short"
        v-html="$t('index.ootbi.description_short')"
      />

      <TheMarkitecture @click="ootbiAnchorClickHandler" />

      <p
        class="description-long"
        v-html="$t('index.ootbi.description_long')"
      />

      <div class="cta">
        <h4 v-html="$t('index.ootbi.cta.title')" />

        <Buttons.Link
          fill="solid"
          accent="purple"
          :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
        >
          {{ $t('Request_a_demo') }}
          <SvgArrowForward />
        </Buttons.Link>
      </div>
    </div>
  </section>

  <section class="purple">
    <ul class="wrapper">
      <li ref="secureSectionRef" class="secure">
        <ul class="info">
          <li>
            <SvgLockBw />
            <h3>{{ $t('Secure') }}</h3>
          </li>

          <li>
            <p v-html="$t('index.purple.secure-1')" />
          </li>

          <li>
            <p v-html="$t('index.purple.secure-2')" />
          </li>

          <li>
            <p v-html="$t('index.purple.secure-3')" />
          </li>
        </ul>

        <NuxtImg
          src="/redesign/images/lock-and-yellow-sphere.webp"
          :alt="$t('Alts.Background.secure.lock-sphere')"

          width="816"
          height="772"

          sizes="xs:0 md:500px xl:904px"
          densities="x1 x2"
          format="webp"
          loading="lazy"
        />
      </li>

      <li ref="simpleSectionRef" class="simple">
        <ul class="info">
          <li>
            <SvgLikeBw />
            <h3 v-html="$t('Simple')" />
          </li>

          <li>
            <p v-html="$t('index.purple.simple-1')" />
          </li>

          <li>
            <p v-html="$t('index.purple.simple-2')" />
          </li>

          <li>
            <p v-html="$t('index.purple.simple-3')" />
          </li>
        </ul>

        <NuxtImg
          src="/redesign/images/cubes.webp"
          :alt="$t('Alts.Background.simple.cubes')"

          width="620"
          height="575"

          sizes="xs:0 md:420px xl:698px"
          densities="x1 x2"
          format="webp"
          loading="lazy"
        />
      </li>

      <li ref="powerfulSectionRef" class="powerful">
        <ul class="info">
          <li>
            <SvgRocketBw />
            <h3 v-html="$t('Powerful')" />
          </li>

          <li>
            <p v-html="$t('index.purple.powerful-1')" />
          </li>

          <li>
            <p v-html="$t('index.purple.powerful-2')" />
          </li>

          <li>
            <p v-html="$t('index.purple.powerful-3')" />
          </li>
        </ul>

        <NuxtImg
          src="/redesign/images/storage.webp"
          :alt="$t('Alts.Background.powerful.disks')"

          width="769"
          height="679"

          sizes="xs:0 md:500px xl:769px"
          densities="x1 x2"
          format="webp"
          loading="lazy"
        />
      </li>
    </ul>
  </section>

  <section class="feedback">
    <div class="wrapper">
      <h3 v-html="$t('index.feedback.title')" />
    </div>

    <FeedbackSlider />
  </section>

  <Awards />

  <section class="three-reasons bg-purple-gradient">
    <div class="wrapper">
      <div class="info">
        <h3 v-html="$t('3_Reasons_Ootbi_is_Best_Storage_for_Veeam')" />
        <p v-html="$t('index.three-reasons.description')" />
      </div>

      <NuxtImg
        src="/redesign/images/whitepaper/3-reasons-ootbi-is-the-best-storage-for-veeam-shadow.webp"
        :alt="$t('Alts.three-reasons-whitepaper-screenshot')"

        width="592"
        height="561"

        sizes="xs:0 md:330px xl:562px"
        densities="x1 x2"
        format="webp"
        loading="lazy"
      />

      <div class="cta">
        <p v-html="$t('index.three-reasons.cta.title')" />

        <Buttons.Regular @click="setFormState('visible', true)">
          {{ $t('Read_White_Paper') }}
          <SvgArrowForward />
        </Buttons.Regular>
      </div>
    </div>
  </section>

  <section class="cta">
    <div class="wrapper">
      <h3 v-html="$t('index.cta.title')" />

      <div class="row">
        <CtaBlock
          :title="$t('index.cta.left.title')"
          :description="$t('index.cta.left.description')"
          accent="purple"
        >
          <Buttons.Link
            fill="outline"
            accent="white"
            :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
          >
            {{ $t('Schedule_Now') }}
            <SvgArrowForward />
          </Buttons.Link>
        </CtaBlock>

        <CtaBlock
          :title="$t('index.cta.right.title')"
          :description="$t('3_Reasons_Ootbi_is_Best_Storage_for_Veeam')"
          accent="pink"
        >
          <Buttons.Regular
            fill="outline"
            accent="white"
            data-id="cta-downloadWhitePaper"
            @click="setFormState('visible', true)"
          >
            {{ $t('Download_Now') }}
            <SvgArrowForward />
          </Buttons.Regular>
        </CtaBlock>
      </div>
    </div>
  </section>

  <WrapperOverlay
    :opened="formState.visible"
    accent="light"
    blur
  >
    <WrapperForm @close="setFormState('visible', false)">
      <template #title>
        <transition name="fade" mode="out-in">
          <span v-if="formState.submitted">
            {{ $t('Download_Asset') }}
          </span>

          <span v-else>
            {{ $t('Provide_your_details_to_get_the_asset') }}
          </span>
        </transition>
      </template>

      <template #default>
        <transition name="fade" mode="out-in">
          <Modals.DownloadAsset v-if="formState.submitted" />

          <Forms.GetAsset
            v-else
            @loading="setLoading"
            @submitted="onSubmitted"
          />
        </transition>
      </template>
    </WrapperForm>
  </WrapperOverlay>
</template>

<script setup lang="ts">
// components
import Awards from '~/components/redesign/Awards.vue';
import PartnersMarquee from '~/components/redesign/PartnersMarquee.vue';
import Buttons from '~/components/redesign/Buttons';
import TheMarkitecture from '~/components/redesign/TheMarkitecture.vue';
import CtaBlock from '~/components/redesign/CtaBlock.vue';
import FeedbackSlider from '~/components/redesign/FeedbackSlider.vue';
import WrapperOverlay from '~/components/WrapperOverlay.vue';
import WrapperForm from '~/components/WrapperForm.vue';
import Modals from '~/components/redesign/Modals';
import Forms from '~/components/redesign/Forms'

// composable
import useApiSeo from '~/composables/useApiSeo';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// utils
import scrollToElement from '~/utils/scrollToElement';

// constants
import { PATHS } from '~/constants/nav-items';

// stores
import useUIStore from '~/stores/ui';

const { setLoading } = useUIStore();
const localePathWithSlash = useLocalePathWithSlash();

const formState = reactive({
  visible: false,
  loading: false,
  submitted: false,
});
const setFormState = <K extends keyof typeof formState> (key: K, value: typeof formState[K]) => {
  formState[key] = value;
};

const onSubmitted = () => {
  setLoading(false);
  setFormState('submitted', true);
};

const secureSectionRef = ref<null | HTMLElement>(null);
const simpleSectionRef = ref<null | HTMLElement>(null);
const powerfulSectionRef = ref<null | HTMLElement>(null);

const ootbiAnchorClickHandler = (value: 'secure' | 'simple' | 'powerful') => {
  const nodeEl = ({
    secure: secureSectionRef.value,
    simple: simpleSectionRef.value,
    powerful: powerfulSectionRef.value,
  })[value];

  if (nodeEl instanceof HTMLElement) {
    scrollToElement(nodeEl);
  }
};

useApiSeo('index');
</script>

<style scoped lang="scss">
@import "$/mixins/size";
@import "$/mixins/media";
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/colors";
@import "$/variables/shadows";

section.hero {
  overflow: hidden;

  .wrapper {
    padding: 8rem 2rem 13.3rem 2rem;
    @include wrapper(80rem);

    @include tablet {
      padding: 4rem 2rem 0 2rem;
    }

    @include mobile {
      padding: 2.5rem 1rem 0 1rem;
    }

    div.info {
      position: relative;

      @include flex-start-start;
      flex-direction: column;

      gap: 2rem;
      max-width: 37rem;

      @include mobile {
        gap: 1.5rem;
      }

      h1 {
        @include h1;

        span {
          color: map-get($colors, 'green');
        }
      }

      p {
        @include body-1;
      }

      div.actions {
        @include flex-start-start;
        flex-direction: column;
        gap: 1rem;
        width:100%;

        [data-component-name="Buttons.Regular"], a {
          @include mobile {
            width: 100%;
            display: block;
          }
        }

        button {
          @include flex-center-center;
          gap: 0.25rem;
        }

        a {
          display: none;

          @include mobile {
            display: block;
            width: 100%;
          }
        }

        .action-request-demo {
          svg {
            fill: token('icon-primary');
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: calc(100% + 5.5%);
        z-index: 0;

        width: auto;
        height: auto;

        @include media(1512px) {
          top: -0.5rem;
        }

        @include tablet {
          position: relative;
          z-index: -1;

          top: 1.7rem;
          left: 0.7rem;
          width: 120vw;
        }

        @include mobile {
          top: 1rem;
          left: -0.4rem;
          width: 140vw;
        }
      }
    }
  }
}

section.ootbi {
  padding: 10.25rem 0;

  @include tablet {
    padding: 8rem 0;
  }

  @include mobile {
    padding: 5rem 0;
  }

  .wrapper {
    @include wrapper(80rem);
    padding: 0 2rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      padding: 0 1rem;
    }

    h2 {
      @include h2;
      text-align: center;
      margin-bottom: 2rem;

      @include tablet {
        margin-bottom: 1.5rem;
      }

      @include mobile {
        margin-bottom: 1rem;
      }

      span {
        color: map-get($colors, 'green');
      }
    }

    p.description-short,
    p.description-long {
      @include body-2;
    }

    p.description-short {
      max-width: 50rem;
      text-align: center;
      margin-bottom: 5rem;

      @include tablet {
        margin-bottom: 3rem;
      }
    }

    [data-component-name="TheMarkitecture"] {
      margin-bottom: 5rem;

      @include tablet {
        margin-bottom: 3rem;
      }
    }

    p.description-long {
      max-width: 75%;
      align-self: flex-start;
      margin-bottom: 2rem;

      @include tablet {
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
    }

    .cta {
      @include flex-center-sb;

      align-self: flex-start;
      width: 100%;

      @include tablet {
        @include flex-start-start;
        flex-direction: column;
        gap: 2rem;
      }

      h4 {
        @include h4;

        span {
          color: map-get($colors, 'green');
        }
      }

      [data-component-name="Buttons.Link"] {
        white-space: nowrap;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}

section.purple {
  position: relative;
  overflow: hidden;
  background-color: token('surf-cont-secon-action');

  ul.wrapper {
    @include flex-start-sb;
    @include wrapper(80rem);
    flex-direction: column;

    padding: 14.5rem 2rem 16rem 2rem;
    gap: 14.5rem;

    @include tablet {
      padding: 3.9rem 2rem 3rem 2rem;
      gap: 2.8rem;
    }

    @include mobile {
      padding: 2.1rem 1rem 2.4rem 1rem;
      gap: 1.23rem;
    }

    > li {
      max-width: 37rem;
      width: 100%;

      @include tablet {
        max-width: 44vw;
      }

      @include mobile {
        max-width: 100%;
      }

      ul.info {
        position: relative;
        z-index: 1;

        li {
          @include flex-start-start;
          padding: 1.5rem 0;
          color: token('text-reversed');
          border-bottom: 1px solid token('outline-primary');

          @include tablet {
            flex-direction: column;
          }

          @include mobile {
            padding: 0.85rem 0;
          }

          &:last-of-type {
            border-bottom: unset;
          }

          svg {
            margin-right: 2rem;

            @include tablet {
              margin-top: -0.5rem;
              margin-bottom: 1rem;
            }
          }

          h3 {
            @include h3;
            margin-bottom: 0.3rem;

            @include tablet {
              margin-bottom: 0;
            }

            @include mobile {
              margin-bottom: 0.65rem;
            }
          }

          p {
            @include body-1;
          }
        }
      }

      img {
        position: absolute;

        @include mobile {
          display: none;
        }
      }
    }

    li.secure {
      img {
        width: 56.5rem;
        height: auto;

        top: -2.75rem;
        left: 50.9vw;

        @include tablet {
          top: 3.9rem;
          left: 51.4vw;

          width: 31.25rem;
          height: 29.56494rem;
        }
      }
    }

    li.simple {
      align-self: flex-end;

      img {
        width: 43.625rem;
        height: auto;

        top: 42.75rem;
        right: 50.8vw;

        @include tablet {
          top: 36.15rem;
          right: 51.7vw;

          width: 26.25rem;
          height: 24.34475rem;
        }
      }
    }

    li.powerful {
      img {
        width: 48.0625rem;
        height: auto;

        bottom: 5.4rem;
        left: 51vw;

        @include tablet {
          bottom: 4rem;
          left: 51.5vw;

          width: 31.25rem;
          height: 27.59263rem;
        }
      }
    }
  }
}

section.feedback {
  padding: 10.25rem 0 9.65rem 0;

  @include tablet {
    padding: 8rem 0 6rem 0;
  }

  @include mobile {
    padding: 5rem 0 2rem 0;
  }

  .wrapper {
    @include wrapper(80rem);
    padding: 0 2rem;

    @include mobile {
      padding: 0 1rem;
    }

    h3 {
      @include h3;
    }
  }

  [data-component-name="FeedbackSlider"] {}
}

section.three-reasons {
  padding: 8rem 2rem;
  color: token('text-reversed');

  @include tablet {
    padding: 5rem 2rem;
  }

  @include mobile {
    padding: 5rem 1rem;
  }

  .wrapper {
    @include wrapper(80rem);

    display: grid;
    grid-gap: 3rem;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
    }

    .info {
      grid-column: 1/2;
      grid-row: 1/2;

      @include tablet {
        grid-column: 1/3;
      }

      h3 {
        @include h3;
        margin-bottom: 2rem;

        @include tablet {
          margin-bottom: 1.5rem;
        }
      }

      p {
        @include body-2;
      }
    }

    img {
      grid-column: 2/3;
      grid-row: 1/4;

      @include tablet {
        grid-row: 2/3;
        justify-self: end;

        width: 100%;
        height: auto;
      }

      @include mobile {
        display: none;
      }
    }

    .cta {
      grid-column: 1/2;
      grid-row: 2/3;

      @include tablet {
        align-self: center;
      }

      @include mobile {
        grid-column: 1/3;
      }

      p {
        @include subtitle-1;
        margin-bottom: 2rem;

        @include tablet {
          margin-bottom: 1.5rem;

          br {
            display: none;
          }
        }
      }

      [data-component-name="Buttons.Link"] {
        display: block;
        width: max-content;

        @include mobile {
          width: 100%;
        }
      }
    }
  }
}

section.cta {
  padding: 8rem 2rem 10.25rem 2rem;

  @include tablet {
    padding: 5rem 2rem 7.9rem 2rem;
  }

  @include mobile {
    padding: 4rem 1rem 5.05rem 1rem;
  }

  .wrapper {
    @include wrapper(80rem);
    padding: 0 2rem;

    @include mobile {
      padding: 2.5rem 1rem 0 1rem;
    }

    h3 {
      @include h3;
      margin-bottom: 5rem;
      max-width: 65%;

      @include tablet {
        max-width: 100%;
        margin-bottom: 2rem;
      }

      @include mobile {
        margin-bottom: 1.6rem;
      }
    }

    .row {
      @include flex-stretch-sb;
      gap: 2rem;

      @include mobile {
        flex-direction: column;
        gap: 1.5rem;
      }

      [data-component-name="CtaBlock"] {
        width: 100%;
      }
    }
  }
}

[data-component-name="WrapperForm"] {
  overflow-y: auto;
}
</style>
